'use client'
import { OpenAPI } from '@LoopKitchen/typescript-client/lib/clients/FrontendDataService'
import dynamic from 'next/dynamic'
import Script from 'next/script'
import { Next13ProgressBar } from 'next13-progressbar'
import { Fragment, Suspense, useEffect, useMemo } from 'react'
import { ToastContainer } from 'react-toastify'
import Appbar from 'src/components/Appbar/Appbar.component'
import Footer from 'src/components/Footer/Footer.component'
import { useLayoutContext } from 'src/context/LayoutContext'

const DialogComp = dynamic(() => import('src/components/Dialog'), {
  ssr: false
})

interface iLayout {
  children: React.ReactNode
}

OpenAPI.BASE = 'https://api.loopapplication.xyz'

const Body = ({ children }: iLayout) => {
  const { hideAppBar, hideFooter, hideAnnouncementBanner } = useLayoutContext()

  const isEmbeded = useMemo(() => {
    return window.location.pathname.endsWith('embeded')
  }, [window.location.pathname])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const searchParams = new URLSearchParams(window.location.search)

      // Store specific parameters in localStorage
      const paramsToStore = ['ref', 'utm_source']
      let referrerValue = ''

      paramsToStore.forEach((param) => {
        const value = searchParams.get(param)
        if (value) {
          referrerValue = value
        }
      })

      // Giving most preferrence to document.referrer
      if (document.referrer) referrerValue = document.referrer

      if (referrerValue) {
        localStorage.setItem('referrer', referrerValue)
      }
    }
  }, [window])

  return (
    <Fragment>
      <div id="root">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          limit={5}
          hideProgressBar={false}
          newestOnTop
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover
          closeButton={true}
          theme="colored"
        />

        {!hideAnnouncementBanner && !isEmbeded && (
          <Suspense fallback={null}>
            <Next13ProgressBar
              color="#196E82"
              height="4px"
              options={{ showSpinner: false }}
            />
          </Suspense>
        )}
        <DialogComp />

        {!hideAppBar && !isEmbeded && <Appbar />}

        <main>{children}</main>

        {!hideFooter && !isEmbeded && <Footer />}
      </div>
      <Script
        src="https://guidebar-backend-727ab3a68ba9.herokuapp.com/install/tryloop_ai"
        defer={true}
      />
    </Fragment>
  )
}
export default Body
