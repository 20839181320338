'use client'
import { createContext, useContext, useEffect, useState } from 'react'

interface LayoutContextInterface {
  hideAppBar: boolean
  hideFooter: boolean
  hideAnnouncementBanner: boolean
  setHideAppBar: (hideAppBar: boolean) => void
  setHideFooter: (hideFooter: boolean) => void
  setHideAnnouncementBanner: (hideAnnouncementBanner: boolean) => void
}

const LayoutContext = createContext({} as LayoutContextInterface)

export const useLayoutContext = () => useContext(LayoutContext)

interface LayoutContextProviderProps {
  children: React.ReactNode
}

export default function LayoutContextProvider({
  children
}: LayoutContextProviderProps) {
  const [hideAppBar, setHideAppBar] = useState(false)
  const [hideFooter, setHideFooter] = useState(false)
  const [hideAnnouncementBanner, setHideAnnouncementBanner] = useState(false)
  return (
    <LayoutContext.Provider
      value={{
        hideAppBar,
        hideFooter,
        hideAnnouncementBanner,
        setHideAppBar,
        setHideFooter,
        setHideAnnouncementBanner
      }}>
      {children}
    </LayoutContext.Provider>
  )
}

type PageLayoutProps = Partial<
  Omit<
    LayoutContextInterface,
    'setHideAppBar' | 'setHideFooter' | 'setHideAnnouncementBanner'
  >
> & {
  children: React.ReactNode
}

export function PageLayout(props: PageLayoutProps) {
  const { children, hideAppBar, hideFooter, hideAnnouncementBanner } = props
  const { setHideAppBar, setHideFooter, setHideAnnouncementBanner } =
    useLayoutContext()
  useEffect(() => {
    setHideAppBar(!!hideAppBar)
    return () => setHideAppBar(false)
  }, [hideAppBar])
  useEffect(() => {
    setHideFooter(!!hideFooter)
    return () => setHideFooter(false)
  }, [hideFooter])
  useEffect(() => {
    setHideAnnouncementBanner(!!hideAnnouncementBanner)
    return () => setHideAnnouncementBanner(false)
  }, [hideAnnouncementBanner])
  return <>{children}</>
}
