'use client'
import { AlertColor, CircularProgress, Stack, Typography } from '@mui/material'
import React, { createContext, useContext, useMemo } from 'react'
import { Id, toast } from 'react-toastify'

interface SnackContextInterface {
  snack: SnackTypes
  downloadSnack: { status: DownloadSnackStatus; message?: string }
  uploadSnack: { status: UploadSnackStatus; message?: string }
  setSnack: ({ type, text }: SnackTypes) => void
  openSuccess: (text: string) => void
  openError: (text: string) => void
  openInfo: (text: string) => void
  setDownloadSnack(params: {
    status: 'complete'
    message?: string
    autoHideDuration?: number
  }): void
  setDownloadSnack(params: {
    status: 'start' | 'close'
    message?: string
  }): void
  setUploadSnack(params: {
    status: 'complete'
    message?: string
    autoHideDuration?: number
  }): void
  setUploadSnack(params: { status: 'start' | 'close'; message?: string }): void
}

export type SnackTypes = {
  open: boolean
  type: AlertColor
  text: string
  autoHideDuration?: number
  description?: string
}

type DownloadSnackStatus = 'start' | 'complete' | 'close'
type UploadSnackStatus = 'start' | 'complete' | 'close'

const initialState = {} as SnackContextInterface

const SnackContext = createContext<SnackContextInterface>(initialState)

export const useSnackData = () => useContext(SnackContext)

const SnackContextProvider = ({ children }: { children: React.ReactNode }) => {
  const snack: SnackTypes = {
    open: false,
    type: 'success',
    text: '',
    description: ''
  }
  const setSnack = ({ type, text }: SnackTypes) => {
    toast[type](text, {
      toastId: text
    })
  }

  const downloadStatusRef = React.useRef<Id>()
  const downloadSnack: SnackContextInterface['downloadSnack'] = {
    status: 'close'
  }
  const setDownloadSnackStatus = ({
    status,
    message
  }: SnackContextInterface['downloadSnack']) => {
    if (status === 'start')
      downloadStatusRef.current = toast.info(
        <Stack direction="row" alignItems="center" spacing={1}>
          <CircularProgress size={24} sx={{ color: '#FFFFFF' }} />
          <Typography color="#FFFFFF" fontSize={16}>
            {message || 'Downloading'}
          </Typography>
        </Stack>,
        {
          // autoClose: 30000,
          closeButton: false,
          hideProgressBar: true,
          icon: false,
          autoClose: false
        }
      )
    else if (status === 'complete') {
      if (downloadStatusRef?.current)
        toast.update(downloadStatusRef?.current, {
          render: message || 'Download Completed',
          type: 'success',
          isLoading: false,
          autoClose: 10000,
          closeButton: true,
          closeOnClick: false
        })
    } else {
      // status === 'close'
      if (downloadStatusRef?.current) toast.dismiss(downloadStatusRef.current)
    }
  }

  const uploadStatusRef = React.useRef<Id>()
  const uploadSnack: SnackContextInterface['uploadSnack'] = {
    status: 'close'
  }
  const setUploadSnackStatus = ({
    status,
    message
  }: SnackContextInterface['uploadSnack']) => {
    if (status === 'start')
      uploadStatusRef.current = downloadStatusRef.current = toast.info(
        <Stack direction="row" alignItems="center" spacing={1}>
          <CircularProgress size={24} sx={{ color: '#FFFFFF' }} />
          <Typography color="#FFFFFF" fontSize={16}>
            {message || 'Uploading'}
          </Typography>
        </Stack>,
        {
          autoClose: 10000,
          closeButton: false,
          hideProgressBar: true,
          icon: false
        }
      )
    else if (status === 'complete') {
      if (uploadStatusRef?.current)
        toast.update(uploadStatusRef?.current, {
          render: message || 'Upload Completed',
          type: 'success',
          isLoading: false,
          autoClose: 5000,
          closeButton: true,
          closeOnClick: false
        })
    } else {
      // status === 'close'
      if (uploadStatusRef?.current) toast.dismiss(uploadStatusRef.current)
    }
  }

  const openSuccess = (text: string) =>
    setSnack({ type: 'success', open: true, text })
  const openError = (text: string) =>
    setSnack({ type: 'error', open: true, text })
  const openInfo = (text: string) =>
    setSnack({ type: 'info', open: true, text })

  function setDownloadSnack(params: {
    status: 'start' | 'close'
    message?: string
  }): void
  function setDownloadSnack(params: {
    status: 'complete'
    message?: string
    autoHideDuration?: number
  }): void
  function setDownloadSnack(params: {
    status: 'start' | 'close' | 'complete'
    message?: string
    autoHideDuration?: number
  }) {
    const { status, autoHideDuration, message } = params
    setDownloadSnackStatus({ status, message })
    if (status === 'complete') {
      setTimeout(() => {
        setDownloadSnackStatus({ status: 'close', message })
      }, autoHideDuration || 3000)
    }
  }

  function setUploadSnack(params: {
    status: 'start' | 'close'
    message?: string
  }): void
  function setUploadSnack(params: {
    status: 'complete'
    message?: string
    autoHideDuration?: number
  }): void
  function setUploadSnack(params: {
    status: 'start' | 'close' | 'complete'
    message?: string
    autoHideDuration?: number
  }) {
    const { status, autoHideDuration, message } = params
    setUploadSnackStatus({ status, message })
    if (status === 'complete') {
      setTimeout(() => {
        setUploadSnackStatus({ status: 'close', message })
      }, autoHideDuration || 3000)
    }
  }

  const contextValue = useMemo(
    () => ({
      snack,
      downloadSnack,
      uploadSnack,
      setSnack,
      openSuccess,
      openError,
      openInfo,
      setDownloadSnack,
      setUploadSnack
    }),
    [
      snack,
      downloadSnack,
      uploadSnack,
      setSnack,
      openSuccess,
      openError,
      openInfo,
      setDownloadSnack,
      setUploadSnack
    ]
  )

  return (
    <>
      <SnackContext.Provider value={contextValue}>
        {children}
      </SnackContext.Provider>
    </>
  )
}

export default SnackContextProvider
